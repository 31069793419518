import React from "react"
import { useStaticQuery, graphql, navigate, Link } from "gatsby"
import Img from "gatsby-image"
import styles from "./DesignerContainer.module.css"
import ContentLayout from "../../common/ContentLayout/ContentLayout"
import Text from "../../common/Text/Text"
import Button from "../../common/Button/Button"
import SocialMediaFooter from "../../common/SocialMediaFooter/SocialMediaFooter"
import Header from "../../common/Header/Header"

const designerText1 = (
  <>
    If you’re looking to expand your horizons in Europe and Asia, look no
    further. We’re always on a lookout for designers and design studios who want
    to create world-changing work with global companies in{" "}
    <Link to="/#industries">various industries</Link>.
  </>
)

const designerText2 =
  "Share your details with a portfolio and let us know what opportunities you seek. We’ll contact you when there’s an opportunity that matches with you. And it’s all for free!"

const DesignerContainer = () => {
  const data = useStaticQuery(graphql`
    query {
      website: file(relativePath: { eq: "background-female-crop.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <Header />
      <ContentLayout title="For Designers">
        <Img
          fluid={data.website.childImageSharp.fluid}
          className={styles.image}
          imgStyle={{
            objectPosition: "100% 100%",
          }}
        />
        <Text content={designerText1} paragraphBreak="small" />
        <Text content={designerText2} paragraphBreak="small" />
        <div className={styles.buttonWrapper}>
          <Button
            text="Yes, I want to find new opportunities"
            onClick={e => {
              e.preventDefault()
              navigate("/designer_form")
            }}
            style={{
              width: "330px",
            }}
          />
        </div>
        <SocialMediaFooter />
      </ContentLayout>
    </div>
  )
}

export default DesignerContainer
