import React from "react"
import styles from "./ContentLayout.module.css"

const ContentLayout = ({ children, title }) => (
  <div className={styles.container}>
    {title && <div className={styles.title}>{title}</div>}
    {children}
  </div>
)

export default ContentLayout
