import React from "react"
import classnames from "classnames/bind"
import styles from "./Text.module.css"

const cx = classnames.bind(styles)

const Text = ({ content, paragraphBreak }) => {
  return (
    <div
      className={cx("textContainer", {
        small: paragraphBreak === "small",
      })}
    >
      {content}
    </div>
  )
}

export default Text
