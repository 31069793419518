import React from "react"
import SEO from "../components/common/SEO/SEO"
import DesignerContainer from "../components/designer/DesignerContainer/DesignerContainer"

export default () => (
  <div className="application">
    <SEO />
    <DesignerContainer />
  </div>
)
